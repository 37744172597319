@import '../App.scss';

.home {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $darkcolor, $alpha: 0.4);
    z-index: -1;
  }
  .header {
    scroll-snap-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    backdrop-filter: blur(2px);
    border-radius: 2rem;
    padding: 4rem;
    h1 {
      span {
        display: inline-block;
        &:nth-child(1) {
          transform: translateY(-4rem) scale(1.5);
        }
        &:nth-child(2) {
          transform: translateY(-2rem) translateX(15rem) scale(1.5);
        }
        &:nth-child(3) {
          transform: translateY(20rem) translateX(15rem) scale(1.5);
        }
        &:nth-child(4) {
          transform: translateY(4rem) translateX(10rem) scale(1.5);
        }
        // animation: gather 2s ease-in-out forwards;
      }
    }
    @media (max-width: $phone) {
      text-align: center;
      padding: 2rem 0;
    }
    @media (max-width: 1024px) {
      text-align: center;
      padding: 2rem 0;
      .bigtext {
        font-size: 2rem;
      }
    }
  }

  .services {
    scroll-snap-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 0;
    padding-top: 4rem;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $darkcolor, $alpha: 0.4);
      z-index: -1;
    }
    @media (max-width: $phone) {
      text-align: center;
      backdrop-filter: blur(2px);
      .container {
        padding-top: 6rem;
      }
    }
    @media (max-width: 320px) {
      text-align: center;
      backdrop-filter: blur(2px);
      .container {
        padding-top: 10rem;
      }
    }
    @media (max-width: 1024px) {
      text-align: center;
      padding: 0;
      .sectitle {
        font-size: 3rem;
      }
      .bigtext {
        font-size: 2rem;
      }
    }
  }
}
// @keyframes gather {
//   to {
//     transform: translateY(0) translateX(0) scale(1);
//   }
// }
