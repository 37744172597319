@import '../../App.scss';

.form {
  background: none;
  text-align: center;
  h2 {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }
  .btn {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 3rem;
    &:hover {
      background-color: $seccolor;
    }
  }
}
