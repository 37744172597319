@import '../../App.scss';

.contact {
  background: linear-gradient(270deg, $seccolor, $lightcolor);
  padding-top: 8rem;
  overflow: hidden;
  .head {
    text-align: center;
    background-color: $maincolor;
    font-size: 3rem;
    padding: 2rem 0;
    margin-bottom: 2rem;
    @media (max-width: $phone) {
      font-size: 1.8rem;
    }
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .contactform {
      border: 1px solid $seccolor;
      border-radius: 2rem;
      width: 100%;
      padding: 1rem;
      margin-bottom: 2rem;
    }
    .contactdetails {
      width: 100%;
      box-shadow: 5px 5px 10px 2px rgba($color: $darkcolor, $alpha: 0.2);
      padding: 2rem 1rem;
      border-radius: 2rem;
      margin-right: 1rem;
      .bigtext {
        display: block;
        font-size: 2rem;
        color: $darkcolor;
      }
    }
  }
  @media (max-width: 1024px) {
    height: auto;
    .container {
      flex-direction: column;
      .contactdetails {
        margin: 0;
        margin-bottom: 2rem;
        .bigtext {
          font-size: 1.8rem;
        }
      }
    }
  }
}
