@import '../../App.scss';

.scrollarrow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $lightcolor;
  margin: 2rem auto;
  p {
    padding: 0.5rem;
  }
}
