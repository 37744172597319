@import '../../App.scss';

.about {
  overflow: hidden;
  .section {
    color: $darkcolor;
    padding-bottom: 6rem;
  }
  .sec1 {
    .ntext {
      color: $darkcolor;
    }
  }
  .sec1 {
    background-color: rgba($color: $seccolor, $alpha: 0.2);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  }
  .sec2 {
    overflow: hidden;
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    ul {
      background-color: rgba($color: $lightcolor, $alpha: 0.7);
      border-radius: 10px;
      padding: 1rem;
      li {
        // background: linear-gradient(to right, $lightcolor 20%, $maincolor);
        // background-clip: text;
        // -webkit-background-clip: text;
        // color: transparent;
      }
    }
    .photo {
      overflow: hidden;
      clip-path: ellipse(70% 100% at 0% 100%);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $seccolor, $alpha: 0.2);
        z-index: -1;
      }
    }
  }
  .sec3 {
    display: flex;
    overflow-x: hidden;
    height: 100vh;
    padding: 6rem 0;
    background-color: rgba($color: $darkcolor, $alpha: 0.9);
    .panel {
      display: flex;
      flex-direction: column;
      text-align: center;
      min-width: 100%;
      height: auto;
      img {
        width: 50%;
        height: 100%;
        border-radius: 2rem;
        box-shadow: 5px 5px 10px 2px rgba($color: $seccolor, $alpha: 0.5);
        margin: auto;
      }
    }
  }
  .sec4 {
    padding: 6rem 0;
  }
  @media (max-width: 1024px) {
    .sec3 {
      padding: 2rem 0;
      .panel {
        img {
          width: 100%;
        }
      }
    }
  }
}
